.header-bar {
  display: flex;
  height: 70px;
  padding: 0 50px;
  justify-content: space-between;
  /* align-items: center; */
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.menu-item {
  color: var(--text-dark, #101828);
  text-align: center;

  /* Text/Medium 16px */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  background-color: #e9f5f6;
  height: 100%;
  cursor: pointer;
}
